import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translation/en.json";
import translationDE from "./translation/de.json";
import translationES from "./translation/es.json";
import translationFR from "./translation/fr.json";
import translationCN from "./translation/cn.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    es: {
      translation: translationES,
    },
    de: {
      translation: translationDE,
    },
    fr: {
      translation: translationFR,
    },
    cn: {
      translation: translationCN,
    },
  },
  lng: "de",
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
