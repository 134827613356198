import React, { useState } from "react";
import "../css/scientificWork.css";
import AIposter from "../images/allergo-eye-ai-poster.png";
import CorrelationPoster from "../images/allergo-eye-correlation-poster.png";
import SummaryPoster from "../images/allergo-eye-summary-poster.png";
import EAACI_2023 from "../images/allergo-eye-EAACI_2023-poster.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";

const ScientificWork = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const [openDialog, setOpenDialog] = useState({
    EAACI_2023: false,
    AIposter: false,
  });

  const handleOpen = (dialogName) => {
    setOpenDialog((prevState) => ({ ...prevState, [dialogName]: true }));
  };

  const handleClose = (dialogName) => {
    setOpenDialog((prevState) => ({ ...prevState, [dialogName]: false }));
  };

  return (
    <div className="scientificWork-container">
      <div className="scientificWork-header">
        <span className="scientificWork-title">
          {t("ScientificWork_title")}
        </span>
      </div>
      <h2 className="scientificWork-subtitle">
        {t("ScientificWork_subtitle")}
      </h2>

      <div className="scientificWork-header">
        <span className="scientificWork-title_2">
          {t("ScientificWork_public_title")}
        </span>
      </div>
      <div className="scientificWork-publications">
        <div className="date-column">
          <p>Mai 2023</p>
          <Button
            startIcon={<ImageIcon />}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => handleOpen("EAACI_2023")}
          >
            {t("show_btn")}
          </Button>

          <Dialog
            open={openDialog.EAACI_2023}
            onClose={() => handleClose("EAACI_2023")}
            maxWidth="lg"
            fullWidth
          >
            <DialogContent>
              <img
                src={EAACI_2023}
                alt="EAACI 2023"
                style={{ width: "100%" }}
              />
            </DialogContent>
          </Dialog>
        </div>
        <div className="text-column">
          <p>
            Yarin Y, Kalaitzidou A, Bodrova K, Mösges R, Kalaidzidi Y.
            Validation of AI-based software for objectification of conjunctival
            provocation test. Journal of Allergy and Clinical Immunology:
            Global. Epub 2023 May 29. DOI:
            <a
              href="https://doi.org/10.1016/j.jacig.2023.100121"
              target="_blank"
            >
              https://doi.org/10.1016/j.jacig.2023.100121
            </a>
          </p>
        </div>
      </div>
      <div className="scientificWork-header">
        <span className="scientificWork-title_3">
          {t("ScientificWork_congress_title")}
        </span>
      </div>
      <div className="scientificWork-publications">
        <div className="date-column">
          <p>{t("month_aug")} 2022</p>
        </div>
        <div className="text-column">
          <p>
            Validation for Artificial Intelligence in Provocation Tests. Review
            of the European Academy of Allergy and Clinical Immunology (EAACI)
            Congress 1st–3rd July 2022, Prague, Czechia, Congress Highlights.
            EMJ (European Medical Journal) Allergy & Immunology, August 2022,
            Page 15, DOI/10.33590/emjallergyimmunol/.
            <a
              href="https://doi.org/10.33590/emjallergyimmunol/10152128"
              target="_blank"
            >
              {" "}
              https://doi.org/10.33590/emjallergyimmunol/10152128
            </a>
          </p>
        </div>
      </div>
      <div className="scientificWork-publications">
        <div className="date-column">
          <p>{t("month_jul")} 2022</p>
        </div>
        <div className="text-column">
          <p>
            Clinical validation of “AllergoEye”—An Artificial Intelligence based
            platform for quantification of conjunctival provocation tests
            Kalaitzidou A., Bodrova K ., Kalaitzidis I., Yarin Y.Allergy
            (European journal of the Academy of Allergy and Clinical
            Immunology). Special Issue: Abstracts from the European Academy of
            Allergy and Clinical Immunology Hybrid Congress, 1‐3 July 2022,
            Volume 78, Issue S111, February 2023, Pages 28,
            <a href="https://doi.org/10.1111/all.15614" target="_blank">
              {" "}
              https://doi.org/10.1111/all.15614
            </a>
          </p>
        </div>
      </div>
      <div className="scientificWork-publications">
        <div className="date-column">
          <p>{t("month_nov")} 2021</p>
          <Button
            startIcon={<ImageIcon />}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => handleOpen("AIposter")}
          >
            Ansehen
          </Button>

          <Dialog
            open={openDialog.AIposter}
            onClose={() => handleClose("AIposter")}
            maxWidth="lg"
            fullWidth
          >
            <DialogContent>
              <img
                src={AIposter}
                alt="AllergoEye 2021"
                style={{ width: "100%" }}
              />
            </DialogContent>
          </Dialog>
        </div>
        <div className="text-column">
          <p>
            Kalaitzidou A., Yarin, Y.Kalaitzidis I.. “AllergoEye” - Fully
            automatic quantitative conjunctival provocation test based on
            Artificial Intelligence. Abstracts from the European Academy of
            Allergy and Clinical Immunology Hybrid Congress, 10-12 July 2021.
            Allergy. 2021 Nov;76 Suppl 110:5-661. doi: 10.1111/all.15093. PMID:
            34783029.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScientificWork;
