import React, { useState } from "react";
import "../css/products.css";
import Produkt from "../images/AllergoEye SoftwareBox.png";
import Image1 from "../images/Zubehör_1  Honor20.png";
import Image2 from "../images/Zubehör_2 Notebook.png";
import Image3 from "../images/Zubehör_3 Maske+Rollstativ.png";
import Image4 from "../images/Zubehör_4 Maske+Gelenkarm_v2.png";
import Image5 from "../images/Zubehör_5 Rollständer.png";
import Image6 from "../images/Zubehör_6 Tischklemme.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import Button from "@mui/material/Button";
import { useCart } from "./CartContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleAddToCart = (item) => {
    addToCart(item);
    setOpenSnackbar(true);
  };

  // Product Data

  const { addToCart } = useCart();

  const product_4 = {
    img: <img src={Produkt} alt={t("Shop_product_4")} />,
    title: t("Shop_product_4"),
    desc: t("Shop_product_4_desc"),
    typ: t("Shop_product_4_version") + " - " + t("Shop_product_4_subtitle"),
    price: 5500,
  };

  const product_5 = {
    img: <img src={Produkt} alt={t("Shop_product_5")} />,
    title: t("Shop_product_5"),
    desc: t("Shop_product_5_desc"),
    typ: t("Shop_product_5_version") + " - " + t("Shop_product_4_subtitle"),
    price: "",
  };

  const product_7 = {
    img: <img src={Image1} alt={t("Shop_product_7")} />,
    title: t("Shop_product_7"),
    desc: t("Shop_product_7_desc"),
    typ: t("Shop_subtitle"),
    price: 499,
  };

  const product_8 = {
    img: <img src={Image2} alt={t("Shop_product_8")} />,
    title: t("Shop_product_8"),
    desc: t("Shop_product_8_desc"),
    typ: t("Shop_subtitle"),
    price: 1499,
  };

  const product_9 = {
    img: <img src={Image3} alt={t("Shop_product_9")} />,
    title: t("Shop_product_9"),
    desc: t("Shop_product_9_desc"),
    typ: t("Shop_subtitle"),
    price: 399,
  };

  const product_10 = {
    img: <img src={Image4} alt={t("Shop_product_10")} />,
    title: t("Shop_product_10"),
    desc: t("Shop_product_10_desc"),
    typ: t("Shop_subtitle"),
    price: 320,
  };

  const product_11 = {
    img: <img src={Image5} alt={t("Shop_product_11")} />,
    title: t("Shop_product_11"),
    desc: t("Shop_product_11_desc"),
    typ: t("Shop_subtitle"),
    price: 79,
  };

  const product_12 = {
    img: <img src={Image6} alt={t("Shop_product_12")} />,
    title: t("Shop_product_12"),
    desc: t("Shop_product_12_desc"),
    typ: t("Shop_subtitle"),
    price: 19,
  };

  const products = [
    product_4,
    product_5,
    product_7,
    product_8,
    product_9,
    product_10,
    product_11,
    product_12,
  ];

  function renderTextWithBreaks(text) {
    return text.split("\n").map((str, index, array) => (
      <React.Fragment key={index}>
        {str}
        {index === array.length - 1 ? null : <br />}
      </React.Fragment>
    ));
  }

  return (
    <div className="products-container">
      <div className="products-header">
        <span className="products-title">SHOP</span>
      </div>
      <h2 className="products-subtitle">{t("Shop_title")}</h2>
      <div className="products-content">
        <div className="products-list">
          {products.map((product, index) => (
            <React.Fragment key={index}>
              {index === 2 && (
                <h2 className="products-subtitle">{t("Shop_subtitle")}</h2>
              )}
              <div className="product-item">
                {product.img}
                <div className="product-details">
                  <h2>{product.title}</h2>
                  <p>{product.typ}</p>
                  <p>{renderTextWithBreaks(product.desc)}</p>

                  {/* Если это продукт_5, то скрыть цену */}
                  {product.title !== t("Shop_product_5") && (
                    <b>
                      {t("shop_price")}: {product.price}€
                    </b>
                  )}

                  {/* Если это продукт_5, то показать другую кнопку */}
                  {product.title === t("Shop_product_5") ? (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: 1 }}
                      onClick={() => navigate("/contact")}
                    >
                      {t("shop_contact_price")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: 1 }}
                      onClick={() => handleAddToCart(product)}
                    >
                      {t("shop_add_to_cart")}
                    </Button>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <p style={{ textAlign: "left", paddingTop: "20px", color: "#171151" }}>
        <b>*</b> {t("shop_footer_info_msg")}
      </p>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity="success"
          elevation={6}
          variant="filled"
        >
          {t("shop_alert_info_msg")} <br />
          <Link
            to="/cart"
            style={{ color: "inherit", textDecoration: "underline" }}
          >
            {t("cart_title")}
          </Link>
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Products;
