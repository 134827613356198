import React, { useState } from "react";
import "../css/allergoEye.css";
import videoSrc from "../video/H20+Maske Präsentation v13_640x480.webm";
import videoPresentationSrc from "../video/allergo-eye-produkt-presentation.mp4";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const AllergoEye = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="allergoeye-container" id="allergo-eye">
      <div className="allergoeye-header">
        <span className="allergoeye-title">ALLERGOEYE</span>
      </div>

      <h2 className="allergoeye-subtitle">{t("AllergoEye_title")}</h2>
      <div className="allergoeye-wrapper">
        <div className="allergoeye-video">
          <video autoPlay loop muted>
            <source src={videoSrc} type="video/webm" />
            Ihr Browser unterstützt das Video-Tag nicht.
          </video>
        </div>
        <div className="allergoeye-content">
          <p>{t("AllergoEye_infoBlock_1")}</p>
          <div className="table-container">
            <div className="allergo-eye-produkt-table">
              <div className="allergo-eye-produkt-table-border">
                <h3>{t("AllergoEye_table_header_1")}</h3>
                <p>{t("AllergoEye_table_text_1")}</p>
              </div>
              <div className="allergo-eye-produkt-table-border">
                <h3>{t("AllergoEye_table_header_2")}</h3>
                <p>{t("AllergoEye_table_text_2")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="allergoeye-wrapper">
        <div className="allergoeye-content">
          <p>{t("AllergoEye_infoBlock_3")}</p>
          <p>{t("AllergoEye_infoBlock_2")}</p>
        </div>
        <div className="allergoeye-video-presentation">
          <video controls playsinline>
            <source src={videoPresentationSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default AllergoEye;
