import React, { useState } from "react";
import "../css/legals.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const Agb = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="legals-container">
      <h1>Allgemeine Geschäftsbedingungen</h1>
      <h2>ÜBERSICHT</h2>
      <p>
        Diese Website wird von AllergoEye GmbH & Co. KG betrieben. Überall auf
        der Webseite beziehen sich die Begriffe "wir", "uns" und "unsere/e" auf
        AllergoEye GmbH & Co. KG. AllergoEye GmbH & Co. KG bietet diese Website,
        einschließlich aller Informationen, Tools und Dienste, die auf dieser
        Website verfügbar sind, Ihnen, dem Benutzer, unter der Bedingung an,
        dass Sie alle hier angegebenen Bedingungen, Konditionen, Richtlinien und
        Hinweise akzeptieren.
      </p>
      <p>
        Wenn Sie unsere Website besuchen und/oder etwas bei uns kaufen, nutzen
        Sie unseren "Dienst" und erklären sich damit einverstanden, an die
        folgenden Allgemeinen Geschäftsbedingungen ("Allgemeine
        Geschäftsbedingungen", "Bedingungen") gebunden zu sein, einschließlich
        der zusätzlichen Geschäftsbedingungen und Richtlinien, die hierin
        erwähnt werden und/oder per Hyperlink verfügbar sind. Diese Allgemeinen
        Geschäftsbedingungen gelten für alle Benutzer der Website, insbesondere
        Benutzer, die Besucher, Anbieter, Kunden, Händler und/oder Verfasser von
        Inhalten sind. Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen
        sorgfältig durch, bevor Sie auf unsere Website zugreifen oder diese
        benutzen. Durch den Zugriff auf oder die Nutzung eines jeglichen Teils
        der Website erklären Sie sich mit diesen Allgemeinen
        Geschäftsbedingungen einverstanden. Sind Sie nicht mit allen
        Geschäftsbedingungen dieser Vereinbarung einverstanden, dürfen Sie nicht
        auf die Website zugreifen oder irgendwelche Dienste nutzen. Wenn diese
        Allgemeinen Geschäftsbedingungen als Angebot betrachtet werden,
        beschränkt sich die Annahme ausdrücklich auf diese Allgemeinen
        Geschäftsbedingungen.
      </p>
      <p>
        Alle neuen Funktionen oder Tools, die zum aktuellen Shop hinzugefügt
        werden, unterliegen ebenfalls den Allgemeinen Geschäftsbedingungen . Sie
        können die aktuellste Version der Allgemeinen Geschäftsbedingungen
        jederzeit auf dieser Seite einsehen. Wir behalten uns das Recht vor,
        Teile dieser Allgemeinen Geschäftsbedingungen durch Veröffentlichung von
        Updates und/oder Änderungen unserer Website zu aktualisieren, zu ändern
        oder zu ersetzen. Es liegt in Ihrer Verantwortung, diese Seite
        regelmäßig auf mögliche Änderungen zu überprüfen. Indem Sie die Website
        nach der Veröffentlichung von irgendwelchen Änderungen weiterhin nutzen
        oder weiterhin darauf zugreifen, gilt dies als Annahme dieser
        Änderungen. Unser Shop wird auf Shopify Inc. gehostet. Shopify stellt
        uns die Online-E-Commerce-Plattform zur Verfügung, die es uns
        ermöglicht, unsere Produkte und Dienstleistungen an Sie zu verkaufen.
      </p>
      <h2>ABSCHNITT 1 - BEDINGUNGEN FÜR DEN ONLINESHOP</h2>
      <p>
        Mit der Zustimmung zu diesen Allgemeinen Geschäftsbedingungen erklären
        Sie, dass Sie in dem Land Ihres Wohnsitzes mindestens volljährig sind
        oder dass Sie in dem Land Ihres Wohnsitz volljährig sind und uns Ihre
        Zustimmung gegeben haben, dass Ihre minderjährigen Angehörigen diese
        Website nutzen dürfen. Sie dürfen unsere Produkte weder für illegale
        oder nicht autorisierte Zwecke nutzen noch dürfen Sie durch die Nutzung
        der Serviceleistung gegen Gesetze in Ihrer Gerichtsbarkeit verstoßen
        (einschließlich, aber nicht beschränkt auf Urheberrechtsgesetze). Sie
        dürfen keine Würmer oder Viren oder sonstigen Code destruktiver Art
        übertragen. Der Verstoß gegen oder die Verletzung irgendeiner dieser
        Bedingungen führt zur sofortigen Kündigung Ihrer Serviceleistungen.
      </p>
      <h2>ABSCHNITT 2 - ALLGEMEINE BEDINGUNGEN</h2>
      <p>
        Wir behalten uns das Recht vor, einer Person die Serviceleistung
        jederzeit aus beliebigem Grund zu verweigern. Sie nehmen zur Kenntnis,
        dass Ihre Informationen (außer Kreditkarteninformationen)
        unverschlüsselt übertragen werden können und (a) Übertragungen über
        verschiedene Netzwerke sowie (b) Änderungen beinhalten können, um den
        technischen Anforderungen von verbundenen Netzwerken oder Geräten zu
        entsprechen und sich an diese anzupassen. Kreditkarteninformationen
        werden bei der Übertragung über Netzwerke immer verschlüsselt. Sie
        verpflichten sich, ohne ausdrückliche schriftliche Genehmigung von uns
        keinen Teil der Serviceleistung, die Nutzung der Serviceleistung oder
        den Zugriff auf die Serviceleistung oder auf jegliche Kontakte auf der
        Website, durch die die Serviceleistung angeboten wird, zu reproduzieren,
        zu vervielfältigen, zu kopieren, zu verkaufen, weiterzuverkaufen oder zu
        verwerten. Die in dieser Vereinbarung verwendeten Überschriften dienen
        nur der Übersichtlichkeit und schränken diese Bedingungen nicht ein oder
        wirken sich anderweitig auf sie aus.
      </p>
      <h2>
        ABSCHNITT 3 - GENAUIGKEIT, VOLLSTÄNDIGKEIT UND RECHTZEITIGKEIT DER
        INFORMATIONEN
      </h2>
      <p>
        Wir sind nicht verantwortlich, wenn die auf dieser Seite zur Verfügung
        gestellten Informationen nicht genau, vollständig oder aktuell sind. Das
        Material auf dieser Website dient nur der allgemeinen Information und
        sollte nicht als alleinige Grundlage für Entscheidungen herangezogen
        werden, ohne primäre, genauere, vollständigere oder aktuellere
        Informationsquellen zu prüfen. Jegliches Vertrauen in das Material auf
        dieser Website geschieht auf eigene Verantwortung. Diese Seite enthält
        möglicherweise gewisse historische Informationen. Historische
        Informationen sind nicht unbedingt aktuell und werden lediglich zu Ihrer
        Orientierung bereitgestellt. Wir behalten uns das Recht vor, Inhalte auf
        dieser Website jederzeit zu ändern, sind aber nicht verpflichtet,
        irgendwelche Informationen auf unserer Website zu aktualisieren. Sie
        stimmen zu, dass Sie verantwortlich dafür sind, Änderungen auf unserer
        Website zu überwachen.
      </p>
      <h2>ABSCHNITT 4 - ÄNDERUNGEN AN DER LEISTUNG UND DEN PREISEN</h2>
      <p>
        Die Preise für unsere Produkte können ohne vorherige Ankündigung
        geändert werden. Wir behalten uns das Recht vor, die Serviceleistung
        (oder einen jeglichen Teil oder Inhalt davon) ohne Ankündigung jederzeit
        zu ändern oder zu beenden. Shopify ist weder Ihnen noch Dritten
        gegenüber haftbar für Änderungen, Preisänderungen, Sperrung oder
        Einstellung des Serviceleistung.
      </p>
      <h2>ABSCHNITT 5 - PRODUKTE ODER SERVICELEISTUNGEN</h2>
      <p>
        Gewisse Produkte oder Serviceleistungen sind möglicherweise nur online
        über die Website erhältlich. Diese Produkte oder Serviceleistungen sind
        unter Umständen nur in begrenzten Mengen vorhanden und unterliegen nur
        der Rückgabe oder dem Umtausch gemäß unseren Rückgaberichtlinien. Wir
        haben uns bemüht, die Farben und Bilder unserer Produkte, die im Shop
        erscheinen, so genau wie möglich darzustellen. Wir können nicht
        garantieren, dass Ihr Computerbildschirm jede Farbe korrekt anzeigt. Wir
        behalten uns das Recht vor, sind aber nicht verpflichtet, den Verkauf
        unserer Produkte oder Serviceleistungen auf gewisse Personen,
        geografische Regionen oder Gerichtsbarkeiten zu beschränken. Wir können
        dieses Recht von Fall zu Fall ausüben. Wir behalten uns das Recht vor,
        die Mengen der von uns angebotenen Produkte oder Serviceleistungen zu
        begrenzen. Alle Produktbeschreibungen oder Produktpreise können
        jederzeit ohne Vorankündigung und nach unserem alleinigen Ermessen
        geändert werden. Wir behalten uns das Recht vor, jedes Produkt jederzeit
        aus dem Angebot zu nehmen. Jedes Angebot für ein Produkt oder eine
        Serviceleistung, das auf dieser Website gemacht wird, ist ungültig, wo
        es verboten ist. Wir garantieren nicht, dass die Qualität von Produkten,
        Serviceleistungen, Informationen oder anderen Materialien, die von Ihnen
        gekauft oder erworben wurden, Ihren Erwartungen entspricht, oder dass
        Fehler in der Serviceleistung korrigiert werden.
      </p>
      <h2>ABSCHNITT 6 - RICHTIGKEIT DER RECHNUNGS- UND KONTOINFORMATIONEN</h2>
      <p>
        Wir behalten uns das Recht vor, jegliche Bestellung, die Sie bei uns
        aufgeben, abzulehnen. Wir können nach eigenem Ermessen die Abnahmemengen
        pro Person, pro Haushalt oder pro Bestellung begrenzen oder stornieren.
        Diese Einschränkungen können Bestellungen umfassen, die von oder unter
        demselben Kundenkonto oder derselben Kreditkarte aufgegeben wurden
        und/oder Bestellungen, die dieselbe Rechnungs- und/oder Lieferadresse
        verwenden. Falls wir eine Bestellung ändern oder stornieren, werden wir
        versuchen, Sie zu benachrichtigen, indem wir die E-Mail und/oder die
        Rechnungsadresse/Telefonnummer kontaktieren, die zum Zeitpunkt der
        Bestellung angegeben wurde. Wir behalten uns das Recht vor, Bestellungen
        einzuschränken oder zu verbieten, die nach unserem alleinigen Ermessen
        den Anschein erwecken, von Händlern, Wiederverkäufern oder Distributoren
        aufgegeben worden zu sein. Sie stimmen zu, aktuelle, vollständige und
        richtige Kauf- und Kontoinformationen für alle in unserem Shop
        getätigten Käufe anzugeben. Sie stimmen zu, Ihr Konto und andere
        Informationen, einschließlich Ihrer E-Mail-Adresse und
        Kreditkartennummern und Ablaufdaten, umgehend zu aktualisieren, damit
        wir Ihre Transaktionen abschließen und Sie bei Bedarf kontaktieren
        können. Für weitere Details lesen Sie bitte unsere Rückgaberichtlinien.
      </p>
      <h2>ABSCHNITT 7- PERSONENBEZOGENE DATEN</h2>
      <p>
        Die Übermittlung von personenbezogenen Daten über den Shop unterliegt
        unserer Datenschutzerklärung. Lesen Sie dafür unsere
        Datenschutzerklärung.
      </p>
      <h2>ABSCHNITT 8 - FEHLER, UNGENAUIGKEITEN UND AUSLASSUNGEN</h2>
      <p>
        Gelegentlich kann es vorkommen, dass unsere Website oder unsere
        Serviceleistung typografische Fehler, Ungenauigkeiten oder Auslassungen
        in Bezug auf Produktbeschreibungen, Preisgestaltung, Werbeaktionen,
        Angebote, Produktversandgebühr, Lieferzeit und Verfügbarkeit enthalten.
        Wir behalten uns das Recht vor, jederzeit und ohne vorherige Ankündigung
        (auch nachdem Sie Ihre Bestellung aufgegeben haben) jegliche Fehler,
        Ungenauigkeiten und Auslassungen zu korrigieren und Informationen zu
        ändern oder zu aktualisieren oder Bestellungen zu stornieren, wenn eine
        Information in Bezug auf die Serviceleistung oder auf irgendeiner
        zugehörigen Website falsch ist. Wir übernehmen keine Verpflichtung zur
        Aktualisierung, Änderung oder Klarstellung von Informationen in Bezug
        auf die Serviceleistung oder auf irgendeiner zugehörigen Website,
        einschließlich, aber nicht beschränkt auf, Preisinformationen, es sei
        denn, dies ist gesetzlich vorgeschrieben. Angeführte
        Aktualisierungsdaten, die in Bezug auf die Serviceleistung oder auf
        irgendeiner zugehörigen Website angegeben werden, sollten nicht als
        Hinweis darauf verstanden werden, dass alle Informationen in Bezug auf
        die Serviceleistung oder auf irgendeiner zugehörigen Website geändert
        oder aktualisiert wurden.
      </p>
      <h2>ABSCHNITT 9 - VERBOTENE ANWENDUNGEN</h2>
      <p>
        Zusätzlich zu anderen Verboten, die in den Allgemeinen
        Geschäftsbedingungen dargelegt sind, ist es Ihnen untersagt, die Website
        oder ihren Inhalt für Folgendes zu nutzen:(a) für rechtswidrige Zwecke;
        (b) um andere zur Durchführung oder Teilnahme an ungesetzlichen
        Handlungen aufzufordern; (c) um gegen internationale, bundesstaatliche,
        provinzielle oder staatliche Vorschriften, Regeln, Gesetze oder örtliche
        Verordnungen zu verstoßen; (d) um unsere Rechte an geistigem Eigentum
        oder die Rechte an geistigem Eigentum anderer zu beeinträchtigen oder zu
        verletzen; (e) um zu belästigen, zu missbrauchen, zu beleidigen, zu
        verletzen, zu diffamieren, zu verleumden, zu verunglimpfen,
        einzuschüchtern oder aufgrund von Geschlecht, sexueller Orientierung,
        Religion, ethnischer Zugehörigkeit, Rasse, Alter, nationaler Herkunft
        oder Behinderung zu diskriminieren; (f) falsche oder irreführende
        Informationen zu übermitteln;  (g) um Viren oder jede andere Art von
        schädlichem Code hochzuladen oder zu übertragen, die auf irgendeine
        Weise die Funktionalität oder den Betrieb der Serviceleistung oder
        irgendeiner zugehörigen Website, anderer Websites oder des Internets
        beeinträchtigen; (h) um persönliche Daten anderer zu sammeln oder zu
        verfolgen; (i) zum Spammen, Phishing, Pharming, Pretexting, Spidering,
        Crawling oder Scraping; (j) für irgendwelche obszönen oder unmoralischen
        Zwecke; oder (k) um die Sicherheitsfunktionen der Serviceleistung oder
        irgendeiner zugehörigen Website, anderer Websites oder des Internets zu
        stören oder zu umgehen. Wir behalten uns das Recht vor, Ihre Nutzung der
        Serviceleistung oder irgendeiner zugehörigen Website zu beenden, wenn
        Sie eine der verbotenen Anwendungen missachten.
      </p>
      <h2>ABSCHNITT 10 - HAFTUNGSAUSSCHLUSS; HAFTUNGSBESCHRÄNKUNG</h2>
      <p>
        Wir übernehmen keine Garantie, Verantwortung oder Gewährleistung dafür,
        dass die Nutzung unserer Serviceleistung ununterbrochen, zeitgerecht,
        sicher oder fehlerfrei erfolgt. Wir garantieren nicht, dass die
        Ergebnisse, die durch die Nutzung der Serviceleistung erzielt werden
        können, genau oder zuverlässig sind. Sie stimmen zu, dass wir von Zeit
        zu Zeit die Serviceleistung auf unbestimmte Zeit entfernen oder
        jederzeit ohne Benachrichtigung an Sie einstellen können. Sie stimmen
        ausdrücklich zu, dass die Nutzung der Serviceleistung bzw. die
        Unfähigkeit zur Nutzung der Servicedienstleistung auf Ihr alleiniges
        Risiko erfolgt. Der Service und alle Produkte und Serviceleistungen, die
        Ihnen über den Service angeboten werden Ihnen (sofern nicht ausdrücklich
        von uns angegeben) "wie besehen" und "wie verfügbar" zur Ihrer Nutzung
        bereitgestellt, und zwar ohne jegliche Zusicherungen, Gewährleistungen
        oder Bedingungen jeglicher Art, weder ausdrücklich noch stillschweigend,
        einschließlich aller stillschweigenden Gewährleistungen oder Bedingungen
        der Handelsüblichkeit, handelsüblichen Qualität, Eignung für einen
        bestimmten Zweck, Belastbarkeit, Rechtsmängelhaftung oder
        Nichtverletzung von Rechten Dritter. Auf keinen Fall sind AllergoEye
        GmbH & Co. KG, unsere Direktoren, leitenden Angestellten, Mitarbeiter,
        Tochtergesellschaften, Vertreter, Auftragnehmer, Praktikanten,
        Lieferanten, Dienstanbieter oder Lizenzgeber haftbar für irgendwelche
        Verletzungen, Verluste, Ansprüche oder jegliche direkte, indirekte,
        zufällige, strafende, besondere oder Folgeschäden jeglicher Art,
        einschließlich, aber nicht beschränkt auf entgangene Gewinne, entgangene
        Einnahmen, verlorene Einsparungen, Datenverlust,
        Wiederbeschaffungskosten oder ähnliche Schäden, unabhängig davon, ob sie
        auf einem Vertrag, einer unerlaubten Handlung (einschließlich
        Fahrlässigkeit) oder verschuldensunabhängigen Haftung beruhen oder sich
        sonstwie aus der Nutzung des Dienstes oder von Produkten ergeben, die
        über den Dienst beschafft wurden, oder für andere Ansprüche, die in
        irgendeiner Weise mit der Nutzung des Dienstes oder eines Produkts
        zusammenhängen, einschließlich, aber nicht beschränkt auf Fehler oder
        Auslassungen in Inhalten oder Verluste oder Schäden jeglicher Art, die
        sich aus der Nutzung des Dienstes oder von Inhalten (oder Produkten)
        ergeben, die über den Dienst veröffentlicht, übertragen oder anderweitig
        zur Verfügung gestellt wurden, selbst wenn auf deren Möglichkeit
        hingewiesen wurde. Da einige Länder oder Gerichtsbarkeiten den
        Ausschluss oder die Haftungsbeschränkung für Folgeschäden oder zufällige
        Schäden in diesen Staaten oder Gerichtsbarkeiten nicht zulassen, ist
        unsere Haftung auf das gesetzlich maximal zulässige Maß beschränkt.
      </p>
      <h2>ABSCHNITT 11 - ENTSCHÄDIGUNG</h2>
      <p>
        Sie verpflichten sich, AllergoEye GmbH & Co. KG und unsere
        Muttergesellschaft, Tochtergesellschaften, verbundenen Unternehmen,
        Partner, leitenden Angestellten, Führungskräfte, Vertreter,
        Auftragnehmer, Lizenzgeber, Dienstleister, Subunternehmer, Lieferanten,
        Praktikanten und Mitarbeiter im Zusammenhang mit Ansprüchen oder
        Forderungen, einschließlich angemessener Anwaltskosten, die von Dritten
        aufgrund Ihrer Verletzung dieser Allgemeinen Geschäftsbedingungen oder
        der Dokumente, die durch Bezugnahme darauf Bestandteil derselben sind,
        oder Ihrer Verletzung eines Gesetzes oder der Rechte eines Dritten
        geltend gemacht werden, zu entschädigen, zu verteidigen und schadlos zu
        halten.
      </p>
      <h2>ABSCHNITT 12 - SALVATORISCHE KLAUSEL</h2>
      <p>
        Sollte sich herausstellen, dass eine Bestimmung dieser Allgemeinen
        Geschäftsbedingungen rechtswidrig, nichtig oder nicht durchsetzbar ist,
        ist diese Bestimmung dennoch im gesetzlich zugelassenen Umfang
        durchsetzbar, und der nicht durchsetzbare Teil gilt als von diesen
        Allgemeinen Geschäftsbedingungen getrennt, wobei eine solche Festlegung
        keine Auswirkungen auf die Gültigkeit und Durchsetzbarkeit der übrigen
        Bestimmungen hat.
      </p>
      <h2>ABSCHNITT 13 - GESAMTE VEREINBARUNG</h2>
      <p>
        Das Versäumnis unsererseits, ein Recht oder eine Bestimmung dieser
        Allgemeinen Geschäftsbedingungen auszuüben oder durchzusetzen, stellt
        keinen Verzicht auf dieses Recht oder diese Bestimmung dar. Diese
        Allgemeinen Geschäftsbedingungen sowie von uns auf dieser Website oder
        in Bezug auf die Serviceleistung veröffentlichten Richtlinien oder
        Betriebsvorschriften, stellen die gesamte Vereinbarung und Übereinkunft
        zwischen Ihnen und uns dar, regeln Ihre Nutzung der Serviceleistung und
        ersetzen alle vorherigen oder gleichzeitigen Vereinbarungen,
        Mitteilungen und Angebote, mündlich oder schriftlich, zwischen Ihnen und
        uns (einschließlich, aber nicht beschränkt auf vorherige Versionen der
        Allgemeinen Geschäftsbedingungen). Etwaige Unklarheiten bei der
        Auslegung dieser Allgemeinen Geschäftsbedingungen sind nicht gegen die
        verfassende Partei zu verstehen.
      </p>
      <h2>ABSCHNITT 14 - GELTENDES RECHT</h2>
      <p>
        Diese Allgemeinen Geschäftsbedingungen und alle separaten
        Vereinbarungen, in deren Rahmen wir Ihnen Serviceleistungen zur
        Verfügung stellen, unterliegen den Gesetzen von Overbeckstraße 33,
        Dresden, 01139 , Deutschland und sind nach diesen auszulegen.
      </p>
      <h2>ABSCHNITT 15 – ÄNDERUNGEN DER ALLGEMEINEN GESCHÄFTSBEDINGUNGEN</h2>
      <p>
        Die aktuellste Version der Allgemeinen Geschäftsbedingungen können Sie
        jederzeit auf dieser Seite einsehen. Wir behalten uns das Recht vor,
        jegliche Teile dieser Allgemeinen Geschäftsbedingungen nach eigenem
        Ermessen zu aktualisieren, zu ändern oder zu ersetzen, indem wir
        Aktualisierungen und Änderungen auf unserer Website veröffentlichen. Es
        liegt in Ihrer Verantwortung, unsere Website regelmäßig auf mögliche
        Änderungen zu überprüfen. Indem Sie unsere Website oder die
        Serviceleistung nach der Veröffentlichung von Änderungen dieser
        Allgemeinen Geschäftsbedingungen weiterhin nutzen oder weiterhin darauf
        zugreifen, gilt dies als Annahme dieser Änderungen.
      </p>
      <h2>ABSCHNITT 16 – KONTAKTDATEN</h2>
      <p>
        Fragen zu den Allgemeinen Geschäftsbedingungen sind unter
        info@allergoeye.com an uns zu richten.
      </p>
    </div>
  );
};

export default Agb;
