import React, { useState } from "react";
import "../css/about.css";
import YY from "../images/yarin.png";
import YK from "../images/kalaidzidis.png";
import ML from "../images/lippmann.png";
import SK from "../images/kalaitzidis.png";
import AK from "../images/kalaitzidou.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const About = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="about-container">
      <div className="about-header">
        <span className="about-title">{t("about_subtitle")}</span>
      </div>
      <h2 className="about-subtitle">{t("about_title")}</h2>
      <div className="team-members">
        <div className="team-member">
          <img
            src={YY}
            alt="Dr. Yury Yarin"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </div>
        <div className="team-member">
          <h3>Dr. Yury Yarin</h3>
        </div>
        <div className="team-member">
          <p>{t("about_yy")}</p>
        </div>
      </div>

      <div className="team-members">
        <div className="team-member">
          <img
            src={YK}
            alt="Dr. Yannis Kalaidzidis"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </div>
        <div className="team-member">
          <h3>Dr. Yannis Kalaidzidis</h3>
        </div>
        <div className="team-member">
          <p>{t("about_yk")}</p>
        </div>
      </div>

      <div className="team-members">
        <div className="team-member">
          <img
            src={ML}
            alt="Mike Lippmann"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </div>
        <div className="team-member">
          <h3>Mike Lippmann</h3>
        </div>
        <div className="team-member">
          <p>{t("about_ml")}</p>
        </div>
      </div>

      <div className="team-members">
        <div className="team-member">
          <img
            src={SK}
            alt="Alexander Kalaitzidis"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </div>
        <div className="team-member">
          <h3>Alexander Kalaitzidis</h3>
        </div>
        <div className="team-member">
          <p>{t("about_developer")}</p>
        </div>
      </div>

      <div className="team-members">
        <div className="team-member">
          <img
            src={AK}
            alt="Alexandra Kalaitzidou BSc."
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </div>
        <div className="team-member">
          <h3>Alexandra Kalaitzidou BSc.</h3>
        </div>
        <div className="team-member">
          <p>{t("about_akb")}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
