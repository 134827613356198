import React, { useState } from "react";
import "../css/contact.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import { TextField, Button, Checkbox, FormControlLabel } from "@mui/material";
import Alert from "@mui/material/Alert";

const Contact = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("success"); // 'error', 'info', 'success', 'warning'

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const [isMapVisible, setIsMapVisible] = useState(false);

  const handleMapDisplay = () => {
    setIsMapVisible(true);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    privacyCheckbox: false,
  });

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "privacyCheckbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // URL, предоставленный Formspree при создании формы
    const FORMSPREE_URL = "https://formspree.io/f/meqbnldo";

    const response = await fetch(FORMSPREE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setAlertSeverity("success");
      setAlertMessage(t("success_msg"));
      setFormData({
        name: "",
        email: "",
        message: "",
        privacyCheckbox: false,
      });
    } else {
      setAlertSeverity("error");
      setAlertMessage(t("error_msg"));
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <span className="contact-title">{t("nav_contact")}</span>
      </div>
      <div className="contact-content">
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <TextField
              required
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              label={t("contact_form_name")}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              required
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              type="email"
              label={t("contact_form_email")}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              required
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              label={t("contact_form_msg")}
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            <div className="checkbox-button-container">
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    color="primary"
                    name="privacyCheckbox"
                    checked={formData.privacyCheckbox}
                    onChange={handleInputChange}
                  />
                }
                label={t("contact_form_privacy_checkbox")}
              />
              <Button type="submit" variant="contained" color="primary">
                {t("contact_send_btn")}
              </Button>
            </div>
          </form>
          <br />
          {alertMessage && (
            <Alert
              severity={alertSeverity}
              onClose={() => setAlertMessage(null)}
            >
              {alertMessage}
            </Alert>
          )}
        </div>
        <div className="contact-address">
          <div className="map-container" onClick={handleMapDisplay}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.8878987024495!2d13.6906669!3d51.0736194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709cfc0ae406f2d%3A0x7eb18cd52969617a!2sOverbeckstra%C3%9Fe%2033%2C%2001139%20Dresden!5e0!3m2!1sde!2sde!4v1696324721726!5m2!1sde!2sde"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            {!isMapVisible && (
              <div className="map-overlay">
                Klicken Sie hier um die Karte in Google Maps anzuzeigen.
                Gleichzeitig akzeptieren Sie damit, dass Ihre Daten von Google
                verarbeitet werden.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
