import React, { useState, useEffect } from "react";
import "../css/cookieBanner.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const CookieBanner = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("isCookieAccepted");
    if (!isCookieAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("isCookieAccepted", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-banner">
      <p>{t("cookie_msg")}</p>
      <button onClick={handleAccept}>OK</button>
    </div>
  );
};

export default CookieBanner;
