import React, { useState } from "react";
import "../css/imageBanner.css";
import enFlag from "../images/en-flag.png";
import deFlag from "../images/de-flag.png";
import esFlag from "../images/es-flag.png";
import frFlag from "../images/fr-flag.png";
import cnFlag from "../images/cn-flag.png";
import aiLogo from "../images/AllergoEye_Logo_v3.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const ImageBanner = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="image-banner" id="home">
      <div className="text-container">
        <img src={aiLogo} alt="AI-Imaging" style={{ width: "22rem" }} />
        <p>{t("banner_title")}</p>
        <p>{t("banner_subtitle")}</p>
        <div className="button-container">
          <a
            href="https://www.ai-medimaging.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="custom-button ai-medimaging"
          >
            AI-MEDIMAGING
          </a>
          <a href="#allergo-eye" className="custom-button allergoeye">
            ALLERGOEYE
          </a>
        </div>
        <div className="language-container">
          <a
            href="#"
            className="language-button"
            onClick={() => changeLanguage("en")}
          >
            <img src={enFlag} alt="English" /> <span>EN</span>
          </a>
          <a
            href="#"
            className="language-button"
            onClick={() => changeLanguage("de")}
          >
            <img src={deFlag} alt="Deutsch" /> <span>DE</span>
          </a>
          <a
            href="#"
            className="language-button"
            onClick={() => changeLanguage("es")}
          >
            <img src={esFlag} alt="Español" />
            <span>ES</span>
          </a>
          <a
            href="#"
            className="language-button"
            onClick={() => changeLanguage("fr")}
          >
            <img src={frFlag} alt="Français" />
            <span>FR</span>
          </a>
          <a
            href="#"
            className="language-button"
            onClick={() => changeLanguage("cn")}
          >
            <img src={cnFlag} alt="中文" />
            <span>CN</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImageBanner;
