import React, { useState, useEffect } from "react";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import "../css/header.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import enFlag from "../images/en-flag.png";
import deFlag from "../images/de-flag.png";
import esFlag from "../images/es-flag.png";
import frFlag from "../images/fr-flag.png";
import cnFlag from "../images/cn-flag.png";

export default function Header() {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [activeButton, setActiveButton] = useState("home");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    // Event-Listener beim Zerstören der Komponente entfernen
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const navigateAndScroll = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);

    // для секций на главной странице
    if (["home", "allergo-eye"].includes(buttonName)) {
      navigateAndScroll(buttonName);
    }
    // для отдельных страниц
    else {
      const pagePath = buttonName.toLowerCase().replace(" ", "-");
      navigate(`/${pagePath}`);
    }
  };

  const handleLanguageMenuOpen = () => {
    setLanguageMenuOpen(true);
  };

  const handleLanguageMenuClose = () => {
    setLanguageMenuOpen(false);
  };

  const isCartActive = location.pathname === "/cart";

  return (
    <div className={`header ${isCartActive ? "no-hover" : ""}`}>
      {isMobile && (
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleDrawerOpen}
          className="menu-icon"
        >
          <MenuIcon />
        </IconButton>
      )}

      <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
        <List>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("home");
              handleDrawerClose();
            }}
          >
            {t("nav_home")}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("allergo-eye");
              handleDrawerClose();
            }}
          >
            {t("nav_allergoeye")}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("scientific-work");
              handleDrawerClose();
            }}
          >
            {t("nav_scientific_work")}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("shop");
              handleDrawerClose();
            }}
          >
            {t("nav_shop")}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("Support");
              handleDrawerClose();
            }}
          >
            {t("support_tittle")}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("about");
              handleDrawerClose();
            }}
          >
            {t("nav_about")}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("contact");
              handleDrawerClose();
            }}
          >
            {t("nav_contact")}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleButtonClick("cart");
              handleDrawerClose();
            }}
          >
            {t("cart_title")}
          </ListItem>
        </List>
      </Drawer>
      <div className="header-nav">
        <NavLink
          to="/"
          className={`header-button ${
            activeButton === "home" ? "active" : ""
          } ${isCartActive ? "no-hover" : ""}`}
          onClick={() => handleButtonClick("home")}
        >
          {t("nav_home")}
        </NavLink>
        <NavLink
          to="/"
          className={`header-button ${
            activeButton === "allergo-eye" ? "active" : ""
          } ${isCartActive ? "no-hover" : ""}`}
          onClick={() => handleButtonClick("allergo-eye")}
        >
          {t("nav_allergoeye")}
        </NavLink>
        <NavLink
          to="/scientific-work"
          className={`header-button ${
            activeButton === "scientific-work" ? "active" : ""
          } ${isCartActive ? "no-hover" : ""}`}
          onClick={() => handleButtonClick("scientific-work")}
        >
          {t("nav_scientific_work")}
        </NavLink>
        <NavLink
          to="/shop"
          className={`header-button ${
            activeButton === "shop" ? "active" : ""
          } ${isCartActive ? "no-hover" : ""}`}
          onClick={() => handleButtonClick("shop")}
        >
          {t("nav_shop")}
        </NavLink>
        <NavLink
          to="/support"
          className={`header-button ${
            activeButton === "Support" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Support")}
        >
          {t("support_tittle")}
        </NavLink>

        <NavLink
          to="/about"
          className={`header-button ${isCartActive ? "no-hover" : ""}`}
          isActive={(match, location) => {
            if (location.pathname === "/about") {
              return true;
            }
            return activeButton === "about";
          }}
          onClick={() => handleButtonClick("about")}
        >
          {t("nav_about")}
        </NavLink>

        <NavLink
          to="/contact"
          className={`header-button ${isCartActive ? "no-hover" : ""}`}
          isActive={(match, location) => {
            if (location.pathname === "/contact") {
              return true;
            }
            return activeButton === "contact";
          }}
          onClick={() => handleButtonClick("contact")}
        >
          {t("nav_contact")}
        </NavLink>

        <NavLink
          to="/cart"
          className={`header-cart-icon ${isCartActive ? "active" : ""}`}
        >
          <ShoppingBasketOutlinedIcon />
        </NavLink>
      </div>
      <div className="language-dropdown">
        <Select
          value={currentLanguage}
          onChange={(e) => changeLanguage(e.target.value)}
          label="Language"
          style={{ marginRight: "20px" }}
          open={languageMenuOpen}
          onOpen={handleLanguageMenuOpen}
          onClose={handleLanguageMenuClose}
        >
          <MenuItem value="en">
            <img
              src={enFlag}
              alt="English"
              style={{
                width: "25px",
                marginRight: "10px",
                verticalAlign: "middle",
              }}
            />{" "}
            EN
          </MenuItem>
          <MenuItem value="de">
            <img
              src={deFlag}
              alt="Deutsch"
              style={{
                width: "25px",
                marginRight: "10px",
                verticalAlign: "middle",
              }}
            />{" "}
            DE
          </MenuItem>
          <MenuItem value="es">
            <img
              src={esFlag}
              alt="Español"
              style={{
                width: "25px",
                marginRight: "10px",
                verticalAlign: "middle",
              }}
            />{" "}
            ES
          </MenuItem>
          <MenuItem value="fr">
            <img
              src={frFlag}
              alt="Français"
              style={{
                width: "25px",
                marginRight: "10px",
                verticalAlign: "middle",
              }}
            />{" "}
            FR
          </MenuItem>
          <MenuItem value="cn">
            <img
              src={cnFlag}
              alt="中文"
              style={{
                width: "25px",
                marginRight: "10px",
                verticalAlign: "middle",
              }}
            />{" "}
            CN
          </MenuItem>
        </Select>
      </div>
    </div>
  );
}
