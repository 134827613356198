import React, { useState } from "react";
import "../css/legals.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const Inprint = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="legals-container">
      <h1>Impressum</h1>
      <h2>Angaben gemäß § 5 TMG</h2>
      <h3>Kontakt</h3>
      <p>
        AllergoEye GmbH & Co. KG <br />
        <br />
        Geschäftsführer: Dr. Yury Yarin <br />
        <br />
        Overbeckstraße 33 <br />
        01139 Dresden <br />
        <br />
        Telefon: +49 351 427 80 555 <br />
        Telefax: +49 351 427 80 556 <br />
        E-Mail: info@allergoeye.com
      </p>
      <p>
        Dresden HRB : 43480 <br />
        Registergericht Dresden <br />
        Steuernummer: 202/105/11904
      </p>
      <h3>Redaktionell verantwortlich</h3>
      <p>
        Dr. Yury Yarin <br />
        Overbeckstr. 33 <br />
        01139 Dresden
      </p>
      <h3>EU-Streitschlichtung</h3>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/.
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>
      <h3>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h3>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
};

export default Inprint;
