import React, { useState, useEffect } from "react";
import "../css/cartPage.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import Cart from "./Cart";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";

const CartPage = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [checked, setChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("success"); // 'error', 'info', 'success', 'warning'

  const [totalSum, setTotalSum] = useState(0);
  const [products, setProducts] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    notes: "",
    total: totalSum,
    products: products,
    invoice: `RN: ${new Date().toLocaleDateString()} - ${
      Math.floor(Math.random() * 90000) + 10000
    }`,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productsString = products.map((product) => product.title).join(", ");

    // URL, предоставленный Formspree при создании формы
    const FORMSPREE_URL = "https://formspree.io/f/xgejdlba";

    const { name, phone, email, zip, city, country, address } = formData;
    if (name && phone && email && zip && city && country && address) {
      setSubmitted(true);

      const updatedFormData = {
        ...formData,
        total: totalSum,
        products: productsString,
      };

      try {
        const response = await fetch(FORMSPREE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFormData),
        });

        const responseData = await response.json();

        if (response.ok) {
          setAlertSeverity("success");
          setAlertMessage(t("cart_order_msg"));
          setFormData({
            name: "",
            phone: "",
            email: "",
            address: "",
            zip: "",
            city: "",
            country: "",
            notes: "",
            total: "",
            products: "",
            invoice: `RN: ${new Date().toLocaleDateString()} - ${
              Math.floor(Math.random() * 90000) + 10000
            }`,
          });
        } else {
          console.error("Ошибка при отправке формы:", responseData.error);
          setAlertSeverity("error");
          setAlertMessage(t("error_msg"));
        }
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
        setAlertSeverity("error");
        setAlertMessage(t("error_msg"));
      }
    }
  };

  return (
    <div className="cartPage-container">
      <h2 className="cartPage-title">{t("cart_title")}</h2>

      <div className="cartPage-content">
        <Cart
          setTotalSum={setTotalSum}
          totalSum={totalSum}
          setProducts={setProducts}
          products={products}
        />
      </div>

      <h2 className="cartPage-subtitle">{t("cart_checkout")}</h2>
      <FormControlLabel
        control={
          <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
        }
        label={
          <>
            Hiermit akzeptiere ich die{" "}
            <a
              href="/agb"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline", color: "inherit" }}
            >
              Allgemeinen Geschäftsbedingungen (AGB)
            </a>
            und bestätige, dass ich diese gelesen und verstanden habe.
          </>
        }
      />

      {checked && (
        <form onSubmit={handleSubmit} className="form-container">
          <TextField
            fullWidth
            required
            label={t("contact_form_name")}
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <div className="flex-row">
            <TextField
              fullWidth
              required
              label="Telefon"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              sx={{ marginRight: 2 }}
              className="flex-item"
            />
            <TextField
              fullWidth
              required
              label={t("contact_form_email")}
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              className="flex-item"
            />
          </div>
          <div className="flex-row">
            <TextField
              fullWidth
              required
              label="PLZ"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              sx={{ marginRight: 2 }}
              className="flex-item-small"
            />
            <TextField
              fullWidth
              required
              label="Stadt"
              name="city"
              value={formData.city}
              onChange={handleChange}
              sx={{ marginRight: 2 }}
              className="flex-item-small"
            />
            <TextField
              fullWidth
              required
              label="Land"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="flex-item-small"
            />
          </div>
          <TextField
            fullWidth
            required
            label="Adresse"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            label="Notizen"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            multiline
            rows={4}
          />
          <Button fullWidth type="submit" variant="contained" color="primary">
            {t("cart_checkout_btn")}
          </Button>
          {submitted && (
            <div className="invoice-message">
              <p>
                Vielen Dank! Ihre Bestellung ist bei uns angekommen und wird
                <u>
                  <b> nach der Bezahlung </b>
                </u>
                versendet.{" "}
              </p>
              <p>
                <b>Skonto 2%: </b> Wenn Sie innerhalb von 10 Tagen bezahlen,
                dürfen Sie 2% vom Rechnungsbetrag abziehen.
              </p>
              <p>
                Bitte überweisen Sie <b>{totalSum},00 € </b>auf folgende
                Bankverbindung:
              </p>{" "}
              <p>Empfänger: AllergoEye GmbH & Co. KG </p>
              <p>IBAN: DE86 1203 0000 1027 7236 73</p>
              <p>BIC: BYLEDEM1001 </p>
              <p>Verwendungszweck: {formData.invoice}</p>
            </div>
          )}
        </form>
      )}
      {alertMessage && (
        <Alert severity={alertSeverity} onClose={() => setAlertMessage(null)}>
          {alertMessage}
        </Alert>
      )}
    </div>
  );
};

export default CartPage;
