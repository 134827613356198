import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ImageBanner from "./components/ImageBanner";
import AllergoEye from "./components/AllergoEye";
import ScientificWork from "./components/ScientificWork";
import Products from "./components/Products";
import About from "./components/About";
import Support from "./components/Support";
import Contact from "./components/Contact";
import ScrollToTopButton from "./components/ScrollToTopButton";
import CartPage from "./components/CartPage";
import { CartProvider } from "./components/CartContext";
import CookieBanner from "./components/CookieBanner";
import Inprint from "./components/Inprint";
import AGB from "./components/AGB";
import Privacy from "./components/Privacy";

function App() {
  return (
    <CartProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/cart" element={<CartPage />} />
            <Route
              path="/"
              element={
                <>
                  <ImageBanner />
                  <AllergoEye />
                </>
              }
            />
            <Route path="/scientific-work" element={<ScientificWork />} />
            <Route path="/shop" element={<Products />} />
            <Route path="/support" element={<Support />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/inprint" element={<Inprint />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/agb" element={<AGB />} />
          </Routes>
          <CookieBanner />
          <ScrollToTopButton />
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
