import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCart } from "./CartContext";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

function Cart({ setTotalSum, totalSum, setProducts, products }) {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { cart, removeFromCart } = useCart();

  // Calculate total sum of cart
  useEffect(() => {
    const sum = cart.reduce((total, product) => total + product.price, 0);
    setTotalSum(sum);
  }, [cart, setTotalSum]);

  useEffect(() => {
    setProducts(cart);
  }, [cart, setProducts]);

  // Get unique products and their counts
  const uniqueProducts = [];
  const counts = {};

  cart.forEach((product) => {
    const key = `${product.title}-${product.desc}-${product.typ}-${product.price}`;
    if (!counts[key]) {
      uniqueProducts.push(product);
      counts[key] = 0;
    }
    counts[key]++;
  });

  const cartJSX = (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>{t("cart_table_title")}</TableCell>
            <TableCell>{t("cart_table_desc")}</TableCell>
            <TableCell>{t("cart_table_typ")}</TableCell>
            <TableCell>{t("cart_table_price")}</TableCell>
            <TableCell>{t("cart_table_del_btn")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uniqueProducts.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>{t("cart_emtpy_msg")}</TableCell>
            </TableRow>
          ) : (
            <>
              {uniqueProducts.map((product) => {
                const key = `${product.title}-${product.desc}-${product.typ}-${product.price}`;
                return (
                  <TableRow key={key}>
                    <TableCell>{counts[key]}</TableCell>
                    <TableCell sx={{ width: "12rem" }}>
                      {product.title}
                    </TableCell>
                    <TableCell>{product.desc}</TableCell>
                    <TableCell>{product.typ}</TableCell>
                    <TableCell sx={{ width: "5rem" }}>
                      {product.price},00 €
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => removeFromCart(key)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={4}>Gesamt</TableCell>
                <TableCell>{totalSum},00 €</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return cartJSX;
}

export default Cart;
