import React, { useState, useEffect } from "react";
import "../css/support.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import videoPresentationSrc from "../video/allergo-eye-produkt-presentation.mp4";
import manualDE from "../docs/Manual_AllergoEye_DE_2.8.pdf";
import manualEN from "../docs/Manual_AllergoEye_ENG_2.8.pdf";
import Button from "@mui/material/Button";
import DescriptionIcon from "@mui/icons-material/Description";

const Support = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const manualToDownload = currentLanguage === "de" ? manualDE : manualEN;

  return (
    <div className="support-container">
      <div className="support-header">
        <span className="support-title">{t("support_tittle")}</span>
      </div>
      <div className="support-wrapper">
        <div className="support-content">
          <p>{t("AllergoEye_infoBlock_3")}</p>
          <p>{t("AllergoEye_infoBlock_2")}</p>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DescriptionIcon />}
              component="a"
              href={manualToDownload}
              download
            >
              {t("Manual")}
            </Button>
          </div>
        </div>
        <div className="support-video-presentation">
          <video controls playsinline>
            <source src={videoPresentationSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Support;
