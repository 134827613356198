import React, { useState } from "react";
import "../css/footer.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import LogoAiMi from "../images/LogoAI-MI.png";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="footer">
      <div className="footer-columns">
        <div className="footer-column">
          <div className="footer-header">{t("footer_adress")}</div>
          <div>
            Overbeckstrasse 33
            <br />
            01139 Dresden
            <br />
            Deutschland
            <br />
            <br />
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-header">{t("footer_links")}</div>
          <div className="footer-links">
            <Link to="/agb">AGB</Link>
            <Link to="/privacy">Datenschutz</Link>
            <Link to="/inprint">Impressum</Link>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-header">{t("nav_contact")}</div>
          <div>
            <span
              style={{ fontWeight: "bold", fontSize: "1rem", color: "#171151" }}
            >
              <a href="mailto:info@allergoeye.com">info@allergoeye.com</a>
            </span>
            <br />
            <br />
            {t("footer_contact_text_1")}
            <br />
            {t("footer_contact_text_2")}
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <span>
          &copy; {currentYear} AllergoEye{" "}
          <span style={{ fontSize: "0.5rem" }}>GmbH & Co. KG</span> | powered by{" "}
          <a
            href="https://www.ai-medimaging.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LogoAiMi}
              alt="AI-MedImaging"
              style={{ width: "150px", verticalAlign: "middle" }}
            />
          </a>
        </span>
      </div>
    </div>
  );
}
